import React, { Component } from 'react'
import { NavLink} from 'react-router-dom'
import Logo from '../assets/img/logo_sm.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartArea, faSeedling, faThLarge} from '@fortawesome/free-solid-svg-icons'


class Sidebar extends Component {
  render() {
    return (
        <div className="sidebar" data-color="green">
          <div className="sidebar-wrapper">
            <div className="logo">
              <div className="row"style={{marginLeft:"2%"}}>
                <div className="col-2"> <img className="" src={Logo} alt="..." /> </div>
                <div className="col"> 
                  <h3 className=""style={{marginTop:"5%"}}>
                    Plantboard
                  </h3>
                </div>
              </div>
            </div>
            <ul className="nav">
              <li className="nav-item">
                <NavLink className="nav-link" to='/dashboard'>
                  <i ><FontAwesomeIcon icon={faChartArea} /></i>
                  <p>Live Data</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to='/devices'>
                <i ><FontAwesomeIcon icon={faThLarge} /></i>
                  <p>Devices</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to='/plants'>
                <i ><FontAwesomeIcon icon={faSeedling}/></i>
                  <p>Plants</p>
                </NavLink>
              </li>

            </ul>
          </div>
        </div>
    )
  }
}

export default Sidebar;