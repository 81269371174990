import React, {Component} from 'react';
import {Line} from 'react-chartjs-2';


class MoistureChartN2 extends Component{
  constructor(){
    super();
    this.state = {
      chartData: null
    }
  }

  componentDidMount(){
    this.getChartData();
  }

  async getChartData(){
    const api_url = 'https://api.thingspeak.com/channels/1379323/feeds.json?api_key=Q8K38OX3OQVQN6HM&results=25';
    const response = await fetch(api_url);
    console.log(response);//This is here b/c I was trying to trouble shoot if the fetch actually was getting anything
    const { feeds } = await response.json();
    // get an array of all field1 values
    const allTimes = feeds.map(feed => new Date(feed.created_at).toLocaleTimeString("en-US"));
    const allField1s = feeds.map(feed => feed.field1);
    console.log(allField1s);//This is here b/c I was trying to trouble shoot if the fetch actually was getting anything
    console.log(allTimes);//This is here b/c I was trying to trouble shoot if the fetch actually was getting anything

    this.setState({
      chartData:{
        labels: allTimes,
        datasets:[
          {
            data:allField1s,
            label: 'Node 2 Moisture (%)',
            backgroundColor: '#74ad74',
            borderColor: '#529b52'
          }
        ]
      }
    });
  }
  
    render() {
      if (this.state.chartData === null)
        return <div>LOADING</div>
      return (
        <div className="chart">
          <Line
            data={this.state.chartData}
            options={{
              title:{
                display:this.props.displayTitle,
                fontSize:25
              },
              legend:{
                display:this.props.displayLegend,
                position:this.props.legendPosition
              }
            }}
          />
        </div>
      )
    }
  }
  
  export default MoistureChartN2;