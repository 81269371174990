import React, { Component } from 'react'
import HumidChart from './HumidChart'
import TempChart from './TempChart'
import MoistureChartN1 from './MoistureChartN1.js'
import MoistureChartN2 from './MoistureChartN2.js'


class Dashboard extends Component {

    render() {
    return (
        <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className='col-md-6'>
                    <p className="display-6">Room Relative Humidity</p>
                    <HumidChart />
                  </div>
                  <div className='col-md-6'>
                    <p className="display-6">Room Temperature</p>
                    <TempChart />
                  </div>
              </div>
              <br/>
              <div className="row">
                  <div className='col-md-6'>
                    <p className="display-6">Arrowhead Soil Moisture</p>
                    <MoistureChartN1 />
                  </div>
                  <div className='col-md-6'>
                    <p className="display-6">Philodendron Soil Moisture</p>
                    <MoistureChartN2 />
                  </div>
                </div>
            </div>
        </div>
    )
  }
}

export default Dashboard
