import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import photon from "../assets/img/device_images/photon.jpg"
import esp32 from "../assets/img/device_images/esp32.jpg"

class UserProfile extends Component {
  render() {
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div className="card card-user">
                <div className="card-image">
                  
                </div>
                <div className="card-body">
                  <div className="author">
                    <Link to='../PhotonInfo'>
                      <img className="avatar border-gray" src={photon} alt="..." />
                      <h5 className="title">Particle Photon</h5>
                    </Link>
                    <p className="description">
                    Environmental Sensor Package
                    </p>
                  </div>
                  <p className="description text-center">
                    <p className="description">Status: Online</p>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="card card-user">
                <div className="card-image">
                  
                </div>
                <div className="card-body">
                  <div className="author">
                    <Link to='/ESP32Info'>
                      <img className="avatar border-gray" src={esp32} alt="..." />
                      <h5 className="title">EPS32</h5>
                    </Link>
                    <p className="description">
                     Soil Monitoring Package
                    </p>
                  </div>
                  <p className="description text-center">
                    <p className="description">Status: Online</p>
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default UserProfile;