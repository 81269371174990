import React, { Component } from 'react'
import esp32 from "../assets/img/device_images/esp32.jpg"

class ESP32Info extends Component {
    render() {
      return (
        <div className="content">
          <div className="container-fluid d-flex justify-content-center">
            <div className="col-md-6">
                <div className="card card-user ">
                  <div className="card-image">
                    
                  </div>
                  <div className="card-body">
                    <div className="author">
                        <img className="avatar border-gray" src={esp32} alt="..." />
                        <h5 className="title">ESP32</h5>
                      <p className="description">
                        Soil Monitoring Package
                      </p>
                    </div>
                    <p className="description text-center">
                      <p className="description">Status: Online</p>
                    </p>
                  </div>
                  <div className="container-fluid">
                    <table class="table table-sm d-flex justify-content-around">
                      <tbody>
                        <tr>
                          <th className="" scope="row">Last Reading:</th>
                          <td className="d-flex justify-content-end">5/15/2021 3:42PM</td>
                        </tr>
                        <tr>
                          <th scope="row">Signal Strength:</th>
                          <td className="d-flex justify-content-end">Strong (-48 dBm)</td>
                        </tr>
                        <tr>
                          <th scope="row">Battery Level:</th>
                          <td className="d-flex justify-content-end">100%</td>
                        </tr>
                        <tr>
                          <th scope="row">Battery State:</th>
                          <td className="d-flex justify-content-end">Discharging</td>
                        </tr>
                        <tr>
                          <th scope="row">Round Trip Time:</th>
                          <td className="d-flex justify-content-end">405 milliseconds</td>
                        </tr>
                        <tr>
                          <th scope="row">RAM Used:</th>
                          <td className="d-flex justify-content-end">90 kB</td>
                        </tr>
                        <tr>
                          <th scope="row">Rate Limited Pushes</th>
                          <td className="d-flex justify-content-end">0 Rejected</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          </div>
        </div>


      )
    }
  }
  
  export default ESP32Info;