import React, { Component } from 'react'
//import Footer from './Footer'
import { Switch, Route, Redirect } from 'react-router-dom'
import Dashboard from './Dashboard'
import Devices from './Devices'
import PhotonInfo from './PhotonInfo'
import ESP32Info from './ESP32Info'
import Plants from './Plants'


class Main extends Component {

  render() {
    return (
      <div className="main-panel">
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/devices" component={Devices} />
          <Route path="/PhotonInfo" component={PhotonInfo} />
          <Route path="/ESP32Info" component={ESP32Info} />
          <Route path="/Plants" component={Plants} />
          <Redirect from='*' to='/dashboard' />
        </Switch>

      </div>
    )
  }
}

export default Main;