import React, {Component} from 'react';
import {Line} from 'react-chartjs-2';


class TempChart extends Component{
  constructor(){
    super();
    this.state = {
      chartData: null
    }
  }

  componentDidMount(){
    this.getChartData();
  }

  async getChartData(){
    const api_url = 'https://api.thingspeak.com/channels/1038990/feeds.json?results=75';
    const response = await fetch(api_url);
    console.log(response);//This is here b/c I was trying to trouble shoot if the fetch actually was getting anything
    const { feeds } = await response.json();
    // get an array of all field1 values
    const allTimes = feeds.map(feed => new Date(feed.created_at).toLocaleTimeString("en-US"));
    const allField1s = feeds.map(feed => feed.field1);

    this.setState({
      chartData:{
        labels: allTimes,
        datasets:[
          {
            data:allField1s,
            label: 'Temperature (°F)',
            backgroundColor: '#ff6384c7',
            borderColor: '#ea486a'
          }
        ]
      }
    });
  }
  
    render() {
      if (this.state.chartData === null)
        return <div>LOADING</div>
      return (
        <div className="chart">
          <Line
            data={this.state.chartData}
            options={{
              title:{
                display:this.props.displayTitle,
                fontSize:25
              },
              legend:{
                display:this.props.displayLegend,
                position:this.props.legendPosition
              }
            }}
          />
        </div>
      )
    }
  }
  
  export default TempChart;