import React, {Component} from 'react';
import {Line} from 'react-chartjs-2';


class HumidChart extends Component{
  constructor(){
    super();
    this.state = {
      chartData: null
    }
  }

  componentDidMount(){
    this.getChartData();
  }

  async getChartData(){
    const api_url = 'https://api.thingspeak.com/channels/1038990/feeds.json?results=75';
    const response = await fetch(api_url);
    const { feeds } = await response.json();
    // get an array of all field3 values
    const allTimes = feeds.map(feed => new Date(feed.created_at).toLocaleTimeString("en-US"));
    const allField3s = feeds.map(feed => feed.field3);
    console.log(allField3s);//This is here b/c I was trying to trouble shoot if the fetch actually was getting anything
    console.log(allTimes);//This is here b/c I was trying to trouble shoot if the fetch actually was getting anything

    this.setState({
      chartData:{
        labels: allTimes,
        datasets:[
          {
            label:'Humidity (%)',
            data:allField3s,
            backgroundColor: '#4475e4c7',
            borderColor: '#306bef'
          }
        ]
      },
      options: {
        responsive: true,
            title: {
            display: true,
            text: 'Plant Room Humidity',
            scales:{
                xAxes:[{
                    ticks:{
                        stepSize: 1
                    }
                }],
                yAxes: [{
                    ticks: {
                        stepSize: 1,
                        sampleSize:10
                    }
                }]
            }
        }
    }
    });
  }
  
    render() {
      console.log(this.state.chartData)
      if (this.state.chartData === null)
        return <div>LOADING</div>
      return (
        <div className="chart">
          <Line
            data={this.state.chartData}
            options={{
              title:{
                display:this.props.displayTitle,
                fontSize:25
              },
              legend:{
                display:this.props.displayLegend,
                position:this.props.legendPosition
              }
            }}
          />
        </div>
      )
    }
  }
  
  export default HumidChart;