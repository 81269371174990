import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AlboMonstera from "../assets/img/plant_images/AlboMonstera.jpg"
import MonsteraDel from "../assets/img/plant_images/MonsteraDel.PNG"
import PDBegonia from "../assets/img/plant_images/PDBegonia.jpg"

class Plants extends Component {
  render() {
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">

            <div className="col-md-3">
              <div className="card card-user">
                <div className="card-image">
                </div>
                <div className="card-body">
                  <div className="author">
                    <Link to='/'>
                      <img className="avatar border-gray" src={AlboMonstera} alt="..." />
                      <h5 className="title">Monstera Albo Variegata</h5>
                    </Link>
                    <p className="description">
                      Current Soil Moisture: 40%
                    </p>
                  </div>
                  <p className="description text-center">
                    <p className="description"></p>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="card card-user">
                <div className="card-image">
                  
                </div>
                <div className="card-body">
                  <div className="author">
                    <Link to='/'>
                      <img className="avatar border-gray" src={MonsteraDel} alt="..." />
                      <h5 className="title">Monstera Deliciosa</h5>
                    </Link>
                    <p className="description">
                      Current Soil Moisture: 48%
                    </p>
                  </div>
                  <p className="description text-center">
                  </p>
                </div>
              </div>
            </div>
            
            <div className="col-md-3">
              <div className="card card-user">
                <div className="card-image">
                  
                </div>
                <div className="card-body">
                  <div className="author">
                    <Link to='/'>
                      <img className="avatar border-gray" src={PDBegonia} alt="..." />
                      <h5 className="title">Polkadot Begonia</h5>
                    </Link>
                    <p className="description">
                      Current Soil Moisture: 65%
                    </p>
                  </div>
                  <p className="description text-center">
                  </p>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    )
  }
}

export default Plants;